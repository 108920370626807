<template>
    <div>
        <div class="text-4xl font-medium">Tools - Check Ecommerce Items</div>
        <div class="card mt-3">
            <div>Tool to check which items are not uploaded into ecommerce site.</div>
            <div class="text-lg font-medium mt-2">Notes</div>
            <div>The following items will not be shown: </div>
            <div>
                <ul>
                    <li>Items with "XXX ---"</li>
                    <li>Items with trailing "(X)"</li>
                </ul>
            </div>
        </div>

        <div class="card mt-3">
            <div v-if="isLoading.ecommerceProductCodes"><span
                    class="pi pi-spin pi-spinner p-button-icon p-button-icon-left mr-2"></span> Loading Products From
                Ecommerce Server... </div>
            <div v-if="isLoading.sqlProducts"><span
                    class="pi pi-spin pi-spinner p-button-icon p-button-icon-left mr-2"></span> Loading Products From
                Local Server... </div>
            <div v-if="isLoading.notInEcommerceProducts"><span
                    class="pi pi-spin pi-spinner p-button-icon p-button-icon-left mr-2"></span> Processing data...
            </div>

            <!-- <div v-if="!processStarted">
                <div>Will take 5 - 10 seconds to process, confirm?</div>
                <Button class="mt-3" @click="confirmStartProcess()" label="Confirm"> </Button>
            </div> -->

            <!-- Show only if isLoading all false -->
            <div
                v-if="!isLoading.ecommerceProductCodes && !isLoading.sqlProducts && !isLoading.notInEcommerceProducts && processStarted">


                <div class="mb-2">Found {{ notInEcommerceProducts.length }} items</div>
                <DataTable :value="notInEcommerceProducts"
                    :loading="isLoading.ecommerceProductCodes || isLoading.sqlProducts || isLoading.notInEcommerceProducts"
                    size="small" scrollable scrollHeight="flex" v-model:filters="filters" filterDisplay="row"
                    dataKey="CODE" paginator :rows="20">
                    <template #empty> No items found. </template>
                    <Column field="CODE" header="Code" :sortable="true" style="width: min-content;">
                        <template #body="{ data }">
                            {{ data.CODE }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                                placeholder="Search" style="width: min-content;" />
                        </template>

                    </Column>
                    <Column field="DESCRIPTION" header="Description" :sortable="true">
                        <template #body="{ data }">
                            {{ data.DESCRIPTION }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                                placeholder="Search by name" />
                        </template>
                    </Column>
                    <Column field="BALSQTY" header="Qty"></Column>
                    <Column field="REFPRICE" header="Price" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.REFPRICE">RM {{ parseFloat(data.REFPRICE).toFixed(2) }}</div>
                        </template>
                    </Column>
                    <Column field="generated_avg_qty_sold_per_mth" header="Avg Qty / Mth" :sortable="true"> </Column>
                    <Column field="generated_avg_sales_per_mth" header="Avg Sales / Mth" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.generated_avg_sales_per_mth">RM {{
                                parseFloat(data.generated_avg_sales_per_mth).toFixed(0) }}</div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { FilterMatchMode } from '@primevue/core/api';
import axios from "axios";

const filters = ref({
    CODE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    DESCRIPTION: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const router = useRouter();
const isLoading = ref({
    ecommerceProductCodes: false,
    sqlProducts: false,
    notInEcommerceProducts: false,
})

const ecommerceProductCodes = ref({});
const sqlProducts = ref([]);
const notInEcommerceProducts = ref([]);

const processStarted = ref(false);

const getAllEcommerceProductCodes = async () => {
    isLoading.value.ecommerceProductCodes = true;

    let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/codes';

    await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        },
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then((data) => {
                // Store as hash set
                let hashSet = {};
                for (let i = 0; i < data.codes.length; i++) {
                    hashSet[data.codes[i]] = true;
                }
                ecommerceProductCodes.value = hashSet;
            });
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
    isLoading.value.ecommerceProductCodes = false;
}


const getLocalItems = async (exportTable = false) => {
    isLoading.value.sqlProducts = true;
    await axios({
        method: "GET",
        url: "/items/all",
    }).then(
        (result) => {
            sqlProducts.value = result.data.items;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
    isLoading.value.sqlProducts = false;
    findItemsNotInEcommerce();
}

const findItemsNotInEcommerce = () => {
    isLoading.value.notInEcommerceProducts = true;

    let notInEcommerceTemp = [];
    for (let i = 0; i < sqlProducts.value.length; i++) {
        if (!ecommerceProductCodes.value[sqlProducts.value[i].CODE]) {
            notInEcommerceTemp.push(sqlProducts.value[i]);
        }
    }

    notInEcommerceProducts.value = notInEcommerceTemp;
    isLoading.value.notInEcommerceProducts = false;
}

const confirmStartProcess = async () => {
    processStarted.value = true;
    await getAllEcommerceProductCodes();
    await getLocalItems();
    await findItemsNotInEcommerce();
}

confirmStartProcess();
//  --------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------
</script>