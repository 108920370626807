<script setup>
import { ref, computed, watch, onBeforeUpdate, getCurrentInstance, onMounted } from 'vue'
import AppTopBar from "@/AppTopbar.vue";
import AppMenu from "@/AppMenu.vue";
import WindowItemSearch from "@/components/WindowItemSearch/WindowItemSearch.vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import { useItemsStore } from '@/stores/items';

import Worker from "@/worker/countOneSecond.worker.js";

// --------------- Refresh Badges Start --------------- //
const REFRESH_BADGES_FREQUENCY = 60;

const isLoading = ref({ badges: false });

const badgesTimerWorker = new Worker();
const badgesRefreshTimeLeft = ref(REFRESH_BADGES_FREQUENCY);

badgesTimerWorker.onmessage = () => {
    if (isLoading.value.badges) {
        return;
    }

    if (badgesRefreshTimeLeft.value <= 0) {
        refreshBadges();
    }
    else {
        badgesRefreshTimeLeft.value -= 1;
    }
};

const refreshBadges = async () => {
    isLoading.value.badges = true;
    // Get Dashboard BE
    await axios({
        method: "GET",
        url: "notification/badges",
    }).then(
        (result) => {
            updateMenuBadge("Home", "Pre-Orders", result.data["Pre-Orders"]);
            updateMenuBadge("Shelfing", "Shelfing Reports", result.data["Shelfing Reports"]);
        },
        (error) => {
            console.log("Error getting badge");
            console.log(error.response.data);
        }
    );

    // Get Ecommerce BE
    const url = process.env.VUE_APP_ECOMMERCE_API_URL + '/notification/badges';
    await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        }
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then((data) => {
                updateMenuBadge("ECommerce", "Orders", data["Orders"]);
            });
        }
    ).catch(
        (error) => {
            console.log("Error Getting Notification Badges From Ecommerce");
            console.log(error);
        }
    );

    badgesRefreshTimeLeft.value = REFRESH_BADGES_FREQUENCY;
    isLoading.value.badges = false;
}

const updateMenuBadge = (menuLabel, itemLabel, badge) => {
    let menuIndex = menu.value.findIndex((item) => item.label === menuLabel);
    if (menuIndex == -1) {
        return;
    }

    let selectedMenu = menu.value[menuIndex];

    let itemsIndex = selectedMenu.items.findIndex((item) => item.label === itemLabel);
    if (itemsIndex == -1) {
        return;
    }

    menu.value[menuIndex].items[itemsIndex].badge = badge;

    // Force update
    menu.value = [...menu.value];
}

// --------------- Refresh Badges END --------------- //

const itemsStore = useItemsStore();

const route = useRoute();
const router = useRouter();

const layoutMode = ref("static");

const staticMenuInactive = ref(false);

const overlayMenuActive = ref(false);

const mobileMenuActive = ref(false);

const showMenus = ref(true);

const menuClick = ref(false);

const menuForEcommerce = ref([{
    label: "ECommerce",
    items: [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            to: "/ecommerce",
        },
        {
            label: "Orders",
            icon: "pi pi-fw pi-shopping-bag",
            to: "/ecommerce/orders",
        },
        {
            label: "Products",
            icon: "pi pi-fw pi-list",
            to: "/ecommerce/products",
        },
        {
            label: "Featured Products",
            icon: "pi pi-fw pi-list",
            to: "/ecommerce/featured-products",
        },
        {
            label: "Brands",
            icon: "pi pi-fw pi-list",
            to: "/ecommerce/brands",
        },
        {
            label: "Categories",
            icon: "pi pi-fw pi-list",
            to: "/ecommerce/categories",
        },

        {
            label: "Users",
            icon: "pi pi-fw pi-users",
            to: "/ecommerce/users",
        },

    ],
},]);

const itemForEcommerceAdmin = ref([{
    label: "Logs",
    icon: "pi pi-fw pi-database",
    to: "/ecommerce/logs",
}]);

const menuForAll = ref([
    {
        label: "Home",
        items: [
            {
                label: "Dashboard",
                icon: "pi pi-fw pi-home",
                to: "/",
            },
            {
                label: "Customer Notify",
                icon: "pi pi-fw pi-bell",
                to: "/customer-notification",
            },
            {
                label: "Pre-Orders",
                icon: "pi pi-fw pi-bell",
                to: "/pre-orders",
            },
            {
                label: "Items",
                icon: "pi pi-fw pi-list",
                to: "/items",
            }
        ],
    },
    {
        label: "Claim",
        items: [
            {
                label: "Claims",
                icon: "pi pi-fw pi-verified",
                to: "/claims",
            },
            {
                label: "Brands",
                icon: "pi pi-fw pi-tag",
                to: "/claim-brands",
            },
            {
                label: "Categories",
                icon: "pi pi-fw pi-tag",
                to: "/claim-categories",
            },
        ],
    },
    {
        label: "Refill",
        items: [
            {
                label: "Teams",
                icon: "pi pi-fw pi-user",
                to: "/teams",
            },
            {
                label: "Refill",
                icon: "pi pi-fw pi-briefcase",
                to: "/refill-groups",
            },
        ],
    },
    {
        label: "Analysis",
        items: [
            {
                label: "Sales",
                icon: "pi pi-fw pi-money-bill",
                to: "/analysis/sales",
            },
            {
                label: "Inventory",
                icon: "pi pi-fw pi-tags",
                to: "/analysis/inventory",
            },
            {
                label: "Dead Stock",
                icon: "pi pi-fw pi-stop",
                to: "/analysis/dead-stock",
            }
        ],
    },
    {
        label: "Purchase",
        items: [
            {
                label: "Agents",
                icon: "pi pi-fw pi-user",
                to: "/agents",
            },
            {
                label: "Suppliers",
                icon: "pi pi-fw pi-user",
                to: "/suppliers",
            },
            {
                label: "Purchase Request",
                icon: "pi pi-fw pi-shopping-cart",
                to: "/purchase-requests",
            },
            {
                label: "Purchase Order",
                icon: "pi pi-fw pi-truck",
                to: "/purchase-orders",
            },
            {
                label: "Inventory Summary",
                icon: "pi pi-fw pi-warehouse",
                to: "/purchase/inventory-summary",
            },
        ],
    },
    {
        label: "Report",
        items: [
            {
                label: "Reorder Stock",
                icon: "pi pi-fw pi-book",
                to: "/report/reorderstock",
            },
        ],
    },
    {
        label: "Shelfing",
        items: [
            {
                label: "Shelfing Reports",
                icon: "pi pi-fw pi-box",
                to: "/shelfing/shelfing-reports",
            },
            {
                label: "Shelfing Summary",
                icon: "pi pi-fw pi-book",
                to: "/shelfing/shelfing-summary",
            },
            {
                label: "Shelfing Update",
                icon: "pi pi-fw pi-refresh",
                to: "/shelfing/shelfing-update",
            },
        ],
    },
    {
        label: "Tools",
        items: [
            {
                label: "Check Ecommerce Items",
                icon: "pi pi-fw pi-list",
                to: "/tools/check-ecommerce-items",
            },
            {
                label: "Stock Issue",
                icon: "pi pi-fw pi-box",
                to: "/stock-issue",
            }
        ],
    }
]);

const menuForAdminOnly = ref([
    {
        label: "Admin",
        items: [
            {
                label: "Users",
                icon: "pi pi-fw pi-users",
                to: "/users",
            },
            {
                label: "API Tokens",
                icon: "pi pi-fw pi-key",
                to: "/externalapitokens",
            },
            {
                label: "Logs",
                icon: "pi pi-fw pi-database",
                to: "/logs"
            }
        ],
    },
]);

const menuForStaff = ref([
    {
        label: "Home",
        items: [
            {
                label: "Customer Notify",
                icon: "pi pi-fw pi-bell",
                to: "/customer-notification",
            }
        ],
    },
    {
        label: "Refill",
        items: [
            {
                label: "Teams",
                icon: "pi pi-fw pi-user",
                to: "/teams",
            },
            {
                label: "Refill",
                icon: "pi pi-fw pi-briefcase",
                to: "/refill-groups",
            },
        ],
    },
    {
        label: "Shelfing",
        items: [
            {
                label: "Shelfing Update",
                icon: "pi pi-fw pi-refresh",
                to: "/shelfing/shelfing-update",
            },
        ],
    },
    {
        label: "Claim",
        items: [
            {
                label: "Claims",
                icon: "pi pi-fw pi-verified",
                to: "/claims",
            },
            {
                label: "Brands",
                icon: "pi pi-fw pi-tag",
                to: "/claim-brands",
            },
            {
                label: "Categories",
                icon: "pi pi-fw pi-tag",
                to: "/claim-categories",
            },
        ],
    },
]);

const menu = ref([]);

// Functions ----------
const onWrapperClick = () => {
    if (!menuClick.value) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
    }

    menuClick.value = false;
}

const isDesktop = () => {
    return window.innerWidth > 1024;
}

const onMenuToggle = () => {
    menuClick.value = true;

    if (isDesktop()) {
        if (layoutMode.value === "overlay") {
            if (mobileMenuActive.value === true) {
                overlayMenuActive.value = true;
            }

            overlayMenuActive.value = !overlayMenuActive.value;
            mobileMenuActive.value = false;
        } else if (layoutMode.value === "static") {
            staticMenuInactive.value = !staticMenuInactive.value;
        }
    } else {
        mobileMenuActive.value = !mobileMenuActive.value;
    }

    event.preventDefault();
}

const onSidebarClick = () => {
    menuClick.value = true;
};

const onMenuItemClick = (event) => {
    if (event.item && !event.item.items) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
    }
};
const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
};

const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
        element.className = element.className.replace(
            new RegExp(
                "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                "gi"
            ),
            " "
        );
};


onBeforeUpdate(() => {
    if (mobileMenuActive.value)
        addClass(document.body, "body-overflow-hidden");
    else removeClass(document.body, "body-overflow-hidden");
});

// Computed -----

const containerClass = computed(
    () => {
        return [
            "layout-wrapper",
            {
                "layout-overlay": layoutMode.value === "overlay",
                "layout-static": layoutMode.value === "static",
                "layout-static-sidebar-inactive":
                    (staticMenuInactive.value && layoutMode.value === "static") ||
                    !showMenus.value,
                "layout-overlay-sidebar-active":
                    overlayMenuActive.value && layoutMode.value === "overlay",
                "layout-mobile-sidebar-active": mobileMenuActive.value,
                "p-input-filled": app.appContext.config.globalProperties.$primevue.config.inputStyle === "filled",
                "p-ripple-disabled": app.appContext.config.globalProperties.$primevue.config.ripple === false,
                "layout-theme-light": app.appContext.config.globalProperties.$appState.theme.startsWith("saga"),
            },
        ];
    }
);

watch(
    () => {
        return route.name;
    },
    (newRoute, oldRoute) => {
        app.appContext.config.globalProperties.$toast.removeAllGroups();
        if (route.name == "login" || route.name == "changepassword") {
            showMenus.value = false;
        } else {
            showMenus.value = true;
        }

        refreshBadges();

        if (oldRoute != undefined && oldRoute != 'login') {
            return;
        }

        // Menu for staff
        if (localStorage.getItem("user_type") == "staff") {
            menu.value = [...menuForStaff.value];
            return;
        }

        menu.value = [...menuForAll.value];

        const ecommerceToken = localStorage.getItem("access_token_ecommerce");
        const rolesStorage = localStorage.getItem("roles");

        if (ecommerceToken && rolesStorage) {
            const roles = JSON.parse(rolesStorage);

            // Check if Admin in roles
            const isAdmin = roles.some((role) => role === "Admin");

            // create deep copy of menuForEcommerce
            const ecommerceMenu = JSON.parse(JSON.stringify(menuForEcommerce.value));

            if (isAdmin) {
                ecommerceMenu[0].items = [...ecommerceMenu[0].items, ...itemForEcommerceAdmin.value];
            }

            menu.value = [...menu.value, ...ecommerceMenu];
        }

        if (localStorage.getItem("user_type") == "admin") {
            menu.value = [...menu.value, ...menuForAdminOnly.value];
        }
    }
);

// ----- On Mounted ----
onMounted(() => {
    refreshBadges();
})


// On Created
const app = getCurrentInstance();
app.appContext.config.globalProperties.$primevue.config.ripple = true;
const auth = localStorage.getItem("access_token");
if (!auth) {
    showMenus.value == false;
    // Push route to login
    router.push({
        path: "/login",
    });
} else {
    axios({
        method: "GET",
        url: "user/checkToken",
    }).then(
        () => {
            showMenus.value = true;
            itemsStore.getItemsFromBackend();
        },
        () => {
            showMenus.value = false;
            localStorage.removeItem("access_token");
            router.push({
                path: "/login",
            });
        }
    );
}
</script>

<template>
    <div :class="containerClass" @click="onWrapperClick">
        <Toast position="top-right" />
        <AppTopBar @menu-toggle="onMenuToggle" :showMenus="showMenus" />
        <div class="layout-sidebar" @click="onSidebarClick" v-if="showMenus">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <!-- <AppFooter /> -->
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>

        <WindowItemSearch> </WindowItemSearch>
    </div>
</template>

<style lang="scss">
@import "./App.scss";
</style>
